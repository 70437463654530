export class Analytics {
  constructor(tracker){
    this.tracker = tracker;
  }

  trackEvent(category, action, label, value){
    try {
      if(this.tracker){
        this.tracker('event', action, {
          'event_category': category,
          'event_label': label,
          'value': value
        });
      }
      
    } catch (error) {
      console.error(error);
    }
  }
}