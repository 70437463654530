class DB {

    static getCookie(cookieName) {
        var name = cookieName + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) !== -1) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }
    static setCookie(cookieName, cookieValue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
    }

    static add(key, value){
        value = JSON.stringify(value);
        if (typeof(Storage) !== "undefined") {
            localStorage.setItem(key, value);
        } else {
            this.setCookie(key, value, 365);
        }
    }
    static get(key) {
        var item = null;
        if (typeof(Storage) !== "undefined") {
            item = localStorage.getItem(key);
        } else {
            item = this.getCookie(key);
        }
        return item ? JSON.parse(item) : item;
    }
};

export default DB;