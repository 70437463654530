'use strict';
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCDialog } from '@material/dialog';
import { MDCDrawer } from '@material/drawer';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTextField } from '@material/textfield';
import { MDCMenu } from '@material/menu';
import { MDCLinearProgress } from '@material/linear-progress';
import { MDCRipple } from '@material/ripple';
import { MDCList } from '@material/list';
import { MDCChipSet } from '@material/chips';
import AutoComplete from './vendor/autocomplete';
import LazyLoad from './vendor/lazyload';
import { Analytics } from './vendor/analytics';
import Fuse from 'fuse.js';
import flatpickr from 'flatpickr';
import Splide from '@splidejs/splide';
import DB from './database.js';
import WebFont from 'webfontloader';
import { Clerk } from '@clerk/clerk-js';

import '../scss/app.scss';

new LazyLoad();

const fontFamilies = ['Roboto:300,400,500,700'];
if (document.documentElement.classList.contains('font-icons-async')) {
	fontFamilies.push('Material+Icons');
}
WebFont.load({ google: { families: fontFamilies } });

window.DB = DB;
const analyticsTracker = new Analytics(window.gtag);

// Load the Clerk SDK
if (window.CLERK_APIKEY) {
	const clerk = new Clerk(window.CLERK_APIKEY);
	window.addEventListener('load', async function () {
		const availablePages = ['route', 'schedule'];
		if (!availablePages.includes(document.documentElement.dataset.page)) {
			console.log('Page not supported');
			return;
		}

		await clerk.load({
			signInForceRedirectUrl: window.location.href,
			signUpForceRedirectUrl: window.location.href,
			afterSignOutUrl: window.location.href,
		});

		const hasAuthCookie = window.DB.getCookie('auth_dialog_dismised');
		if (clerk.user) {
			console.log('Exists user');
			const userButtonEl = document.getElementById('user-button');
			if (userButtonEl) {
				clerk.mountUserButton(userButtonEl);
			}
		} else {
			console.log('No user');

			if (hasAuthCookie) return;
			const authDialogElement = document.getElementById('auth-dialog');
			const authDialog = new MDCDialog(authDialogElement);
			authDialog.scrimClickAction = '';

			setTimeout(() => {
				authDialog.open();
			}, 15000);

			authDialog.listen('MDCDialog:opening', () => {
				console.log('MDCDialog:opening');
				const signInDiv = document.getElementById('sign-in');
				clerk.mountSignIn(signInDiv);
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					`AuthDialog`,
					`OPENED`
				);
			});
			authDialog.listen('MDCDialog:closing', () => {
				console.log('MDCDialog:closing');
				window.DB.setCookie('auth_dialog_dismised', true, 1);
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					`AuthDialog`,
					`CLOSED`
				);
				window.location.reload();
			});
		}
	});
}

// Drawer
const drawerEl = document.querySelector('#sidebar');
const drawer = drawerEl ? MDCDrawer.attachTo(drawerEl) : null;
const listEl = document.querySelector('#sidebar .mdc-list');
if (listEl && drawerEl) {
	listEl.addEventListener('click', (event) => {
		drawer.open = false;
	});
}

// SearchForm display Trigger
const headerMenuTrigger = document.querySelector('#sidebar-menu-trigger');
if (headerMenuTrigger) {
	headerMenuTrigger.addEventListener('click', (evt) => {
		drawer.open = !drawer.open;
	});
}

const sidebarSearchEl = document.querySelector('#sidebar-search');
const sidebarSearch = sidebarSearchEl
	? MDCDrawer.attachTo(sidebarSearchEl)
	: null;
if (sidebarSearch) {
	let closeTrigger = sidebarSearchEl.querySelector('.sidebar-close');
	closeTrigger.addEventListener('click', (event) => {
		sidebarSearch.open = false;
	});
}

// Instantiation
const topAppBarElement = document.querySelector('.mdc-top-app-bar');
if (topAppBarElement) {
	const topAppBar = new MDCTopAppBar(topAppBarElement);
	const topAppBarFoundation = topAppBar.getDefaultFoundation();
	const topAppBarAdapter = topAppBarFoundation.adapter;

	window.onscroll = () => {
		if (topAppBarElement.classList.contains('header-has-extra-row-fixed')) {
			const scrollHeight = topAppBarAdapter.getViewportScrollY();
			if (scrollHeight > topAppBarAdapter.getTopAppBarHeight()) {
				document
					.querySelector('.mdc-top-app-bar__row-extra')
					.classList.add('fixed-row');
			} else {
				document
					.querySelector('.mdc-top-app-bar__row-extra')
					.classList.remove('fixed-row');
			}
		}
	};
}

const mainContentEl = document.getElementById('main-content');
document.body.addEventListener('MDCDrawer:closed', () => {
	mainContentEl.querySelector('input,button,p').focus();
});

// TextField
const textFieldElements = document.querySelectorAll('.mdc-text-field');
[].map.call(textFieldElements, (el) => new MDCTextField(el));

// Buttons
const buttonElements = document.querySelectorAll('.mdc-button');
[].map.call(buttonElements, (el) => new MDCRipple(el));

// AutoComplete
const autoCompleteElements = document.querySelectorAll(
	'.mdc-text-field__input.autocomplete'
);
if (autoCompleteElements) {
	const fuseIndex = Fuse.createIndex(['longName'], window.LOCATIONS);
	[].map.call(autoCompleteElements, (el) => {
		return new AutoComplete({
			selector: el,
			minChars: 1,
			first: true,
			delay: 10,
			wrap: true,
			source: (term, suggest) => {
				if (term && term.trim().length > 0) {
					var options = {
						includeScore: true,
						shouldSort: true,
						threshold: 0.6,
						location: 0,
						distance: 100,
						minMatchCharLength: 1,
						keys: ['longName'],
					};
					var fuse = new Fuse(window.LOCATIONS, options, fuseIndex);
					var suggestions = fuse
						.search(term)
						.map((i) => i.item)
						.slice(0, 20);
					return suggest(suggestions);
				}
				fetch(`/api/suggestions`)
					.then((response) => response.json()) // retorna uma promise
					.then((result) => suggest(result))
					.catch((err) => {
						console.error('Failed retrieving information', err);
						suggest([]);
					});
			},
			menuClass: 'mdc-card mdc-list',
			renderItem: (item, search) => {
				return (
					'<div class="mdc-list-item autocomplete-suggestion" data-id="' +
					item.id +
					'" data-val="' +
					item.longName +
					'">' +
					item.longName +
					'</div>'
				);
			},
			onSelect: (evt, term, item) => {
				el.setAttribute('data-id', item.dataset.id);
				let field_type = el.id.includes('origin-field')
					? 'ORIGIN'
					: 'DESTINATION';
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					`AutoComplete_SELECTED_${field_type}`,
					item.dataset.val
				);
			},
		});
	});
}

const faqEl = document.getElementById('mdc-list-faq');
if (faqEl) {
	const faq = new MDCList(faqEl);
	faq.singleSelection = true;
	faq.listElements.map((listItemEl) => new MDCRipple(listItemEl));
	faq.listen('MDCList:action', (event) => {
		console.log('faq', event.detail);
		let selectedItem = faq.listElements[faq.selectedIndex];
		let meta = selectedItem.querySelector('.mdc-list-item__meta');
		var isActive = selectedItem.classList.toggle('active');
		meta.innerText = isActive ? 'expand_less' : 'expand_more';
	});
}

// SearchForm display Trigger
const searchFormDisplayTriggers = document.querySelectorAll(
	'.search-form-display-trigger'
);
if (searchFormDisplayTriggers) {
	[].map.call(searchFormDisplayTriggers, (el) => {
		el.addEventListener('click', (evt) => {
			sidebarSearch.open = !sidebarSearch.open;
		});
	});
}

// Linear Progress for Reviews
const linearProgressElements = document.querySelectorAll(
	'.mdc-linear-progress'
);
if (linearProgressElements) {
	[].map.call(linearProgressElements, (el) => {
		let linearProgress = MDCLinearProgress.attachTo(el);
		linearProgress.progress = el.dataset.progress ? el.dataset.progress / 5 : 0;
	});
}

function popupWindow(url, title, win, w, h) {
	const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
	const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
	return win.open(
		url,
		title,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
	);
}

// Long reviews texts
function attachReviewEvents() {
	const longReviewsElements = document.querySelectorAll(
		'.review-text__truncate'
	);
	if (longReviewsElements) {
		[].map.call(longReviewsElements, (el) => {
			el.addEventListener(
				'click',
				(event) => {
					let targetElement = event.target.classList.contains(
						'review-text__truncate'
					)
						? event.target
						: event.target.closest('.review-text__truncate');
					if (targetElement) {
						targetElement.classList.remove('review-text__truncate');
					}
				},
				false
			);
		});
	}
}

// Tabs
function updateTabPanel(parentNode, index) {
	let activeTabPanel = parentNode.querySelector('.mdc-tab-panel--active');
	if (activeTabPanel) {
		activeTabPanel.classList.remove('mdc-tab-panel--active');
	}
	let newActiveTabPanel = parentNode.querySelector(
		'.mdc-tab-panel:nth-child(' + (index + 1) + ')'
	);
	if (newActiveTabPanel) {
		newActiveTabPanel.classList.add('mdc-tab-panel--active');
	}
	return newActiveTabPanel;
}

function render(reviews) {
	return new Promise((resolve) => {
		let html = null;
		if (reviews && reviews.length > 0) {
			html = '';
			const reviewTemplate =
				document.getElementById('review-template').innerHTML;
			for (var i = 0; i < reviews.length; i++) {
				let review = reviews[i];
				let customReview = reviewTemplate
					.replace(
						/\$reviewIcon/g,
						review.rating >= 3 ? 'thumb_up' : 'thumb_down'
					)
					.replace('$reviewAuthor', review.userName.toLowerCase())
					.replace('$reviewDate', review.displayDate)
					.replace('$reviewComment', review.comment);

				if (review.comment.length > 70) {
					customReview = customReview.replace(
						'review-text',
						'review-text review-text__truncate'
					);
				}
				html += customReview;
			}
		}
		resolve(html);
	});
}

function getReviewsAndDisplay(url, newActivePanel) {
	fetch(url)
		.then((response) => response.json()) // retorna uma promise
		.then((result) => render(result))
		.then((renderedHTML) => {
			if (renderedHTML) {
				newActivePanel.querySelector('.mdc-card__content').innerHTML =
					renderedHTML;
				var hiddenElement = newActivePanel.querySelector('.hide');
				if (hiddenElement) {
					hiddenElement.classList.remove('hide');
				}
			}
			attachReviewEvents();
		})
		.catch((err) => {
			render([]);
		});
}

(function SchedulerSorter() {
	function sortSchedules(property, reverse) {
		var list = document.querySelector('#schedule-list');
		var scheduleSepartors = Array.from(
			list.querySelectorAll('.mdc-list-divider')
		);
		var scheduleItems = Array.from(list.querySelectorAll('.schedule-item'));

		let sorted = scheduleItems.sort(sorter);
		if (reverse) {
			sorted = sorted.reverse();
		}

		function sorter(a, b) {
			if (parseFloat(a.dataset[property]) < parseFloat(b.dataset[property])) {
				return -1;
			}
			if (parseFloat(a.dataset[property]) > parseFloat(b.dataset[property])) {
				return 1;
			}
			return 0;
		}

		sorted.forEach((e, i) => {
			list.appendChild(e);
			if (scheduleSepartors && scheduleSepartors.length > 0) {
				var listSepartor = scheduleSepartors.shift();
				if (listSepartor) {
					list.appendChild(listSepartor);
				}
			}
		});
	}

	function sortScheduleByProperty(property, sortReverseOrder) {
		var switching = true;
		var shouldSwitch = false;

		while (switching) {
			var schedules = document.querySelectorAll(
				'#schedule-list .schedule-item'
			);
			switching = false;
			//Loop through all list-items:
			for (var i = 0; i < schedules.length - 1; i++) {
				//start by saying there should be no switching:
				shouldSwitch = false;

				var current = schedules[i].dataset[property];
				var next = schedules[i + 1].dataset[property];

				if (parseFloat(current) > parseFloat(next)) {
					/*if next item is alphabetically lower than current item,
              mark as a switch and break the loop:*/
					shouldSwitch = true;
					break;
				}
			}
			if (shouldSwitch) {
				/*If a switch has been marked, make the switch
          and mark that a switch has been done:*/
				schedules[i].parentNode.insertBefore(schedules[i + 1], schedules[i]);
				switching = true;
			}
		}
		if (sortReverseOrder) {
			// Get the parent element.
			const parent = document.querySelector('.schedule-item').parentNode;
			// Shallow copy to array: get a `reverse` method.
			const arr = Array.from(parent.childNodes);
			// `reverse` works in place but conveniently returns the array for chaining.
			arr.reverse();
			// The experimental (as of 2018) `append` appends all its arguments in the order they are given. An already existing parent-child relationship (as in this case) is "overwritten", i.e. the node to append is cut from and re-inserted into the DOM.
			parent.append(...arr);
		}
	}

	let menuTrigger = document.querySelector('#schedule-sorter-menu--trigger');
	let menuElement = document.querySelector('#schedule-sorter-menu');
	if (menuTrigger && menuElement) {
		let scheduleSorterMenu = new MDCMenu(menuElement);
		scheduleSorterMenu.listen('MDCMenu:selected', (event) => {
			console.log(event.detail);

			// scheduleSorterMenu.setSelectedIndex(event.detail.index);
			switch (event.detail.index) {
				case 0:
					sortScheduleByProperty('departure');
					break;
				case 1:
					sortScheduleByProperty('departure', true);
					break;
				case 2:
					sortScheduleByProperty('price');
					break;
				case 3:
					sortScheduleByProperty('duration');
					break;
				default:
					sortScheduleByProperty('departure');
			}
		});

		menuTrigger.addEventListener('click', (event) => {
			scheduleSorterMenu.open = !scheduleSorterMenu.open;
		});
	}

	const chipSetEl = document.querySelector('#schedule-sorter');
	if (chipSetEl) {
		const chipSet = new MDCChipSet(chipSetEl);

		const params = new URL(document.location).searchParams;
		const sort_by = params.get('sortBy');
		const is_reverse = params.get('reverse');
		if (sort_by) {
			sortSchedules(sort_by, is_reverse);
			chipSet.chips.forEach((chip) => {
				if (is_reverse) {
					chip.selected =
						chip.root.dataset.sortReverse && chip.root.dataset.sort === sort_by;
				} else {
					chip.selected =
						!chip.root.dataset.sortReverse &&
						chip.root.dataset.sort === sort_by;
				}
			});
		}
		chipSet.listen('MDCChip:selection', (event) => {
			if (event.detail.selected) {
				const chip = document.querySelector(`#${event.detail.chipId}`);
				if (chip && chip.dataset.href) {
					window.location = chip.dataset.href;
					return;
				}

				if (event.detail.chipId === 'mdc-chip-1') {
					//Earlier
					sortSchedules('departure');
				} else if (event.detail.chipId === 'mdc-chip-2') {
					//Later
					sortSchedules('departure', true);
				} else if (event.detail.chipId === 'mdc-chip-3') {
					// Cheaper
					sortSchedules('price');
				} else if (event.detail.chipId === 'mdc-chip-4') {
					// Faster
					sortSchedules('duration');
				}
			}
		});
	}
})();

(function SchedulesSlider() {
	const element = document.querySelector('.schedules-slider');
	if (!element) return;
	const schedulesSlider = new Splide(element, {
		perPage: 8,
		direction: 'ttb',
		height: `${8 * 72}px`,
		width: '100%',
		pagination: false,
		drag: false,
		wheel: false,
		fixedHeight: '72px',
		classes: {
			arrow: 'splide__arrow schedules-slider__arrow',
		},
		breakpoints: {
			480: {
				perPage: 5,
				height: `${5 * 72}px`,
			},
		},
	});
	schedulesSlider.mount();
	window.scheduleSlider = schedulesSlider;
})();

(function Slider() {
	let options = {
		perPage: 3,
		drag: false,
		perMove: 3,
		gap: '0.5rem',
		padding: '1rem',
		pagination: false,
		fixedWidth: '300px',
		classes: {
			arrow: 'splide__arrow carousel-button mdc-fab mdc-fab--touch',
			prev: 'splide__arrow--prev carousel-button--left',
			next: 'splide__arrow--next carousel-button--right',
		},
		breakpoints: {
			640: {
				perPage: 2,
				perMove: 2,
				focus: 'center',
				trimSpace: false,
				gap: '0.5rem',
				padding: '0.5rem',
			},
			480: {
				perPage: 1,
				perMove: 1,
				focus: 'center',
				trimSpace: true,
				gap: '0.5rem',
				padding: '0.5rem',
			},
		},
	};
	var elms = document.querySelectorAll('.splide');
	for (var i = 0, len = elms.length; i < len; i++) {
		let element = elms[i];
		if (!element.classList.contains('schedules-slider')) {
			new Splide(element, options).mount();
		}
	}
})();

// CompanyLeadDialog
(function CompanyLeadDialog() {
	const COGNITO_AUTH_URL = window.COGNITO_AUTH_URL;
	const leadDialogEl = document.querySelector('#company-lead-dialog');
	const leadDialogTrigger = document.querySelector(
		'.company-lead__dialog-trigger'
	);
	if (leadDialogEl && leadDialogTrigger) {
		const leadDialog = new MDCDialog(leadDialogEl);
		leadDialogTrigger.addEventListener('click', (event) => {
			let isLoggedIn = window.DB.getCookie('user_token');
			if (!COGNITO_AUTH_URL || isLoggedIn) {
				leadDialog.open();
				return;
			}
			let popup = popupWindow(COGNITO_AUTH_URL, 'SignIn', window, 650, 400);
			let popupTick = setInterval(function () {
				if (popup.closed) {
					clearInterval(popupTick);
					isLoggedIn = window.DB.getCookie('user_token');
					if (isLoggedIn) {
						leadDialog.open();
					}
				}
			}, 500);
		});
	}
})();

// ReviewTabBar (Recent/Bad/Good) reviews
(function ReviewTabBar() {
	const reviewTabBarElement = document.querySelector('#review-tab-bar');
	if (reviewTabBarElement) {
		attachReviewEvents();
		let reviewTabPanelElement = reviewTabBarElement.nextSibling;
		let reviewTabBar = new MDCTabBar(reviewTabBarElement);
		reviewTabBar.listen('MDCTabBar:activated', (evt) => {
			console.log('tabbar', evt);
			let nthChildIndex = evt.detail.index;
			let newActiveTabPanel,
				url = null;

			switch (nthChildIndex) {
				case 0: // most recent
					updateTabPanel(reviewTabPanelElement, 0);
					break;

				case 1: // best reviews
					newActiveTabPanel = updateTabPanel(reviewTabPanelElement, 1);
					url =
						'/api/reviews/' +
						newActiveTabPanel.dataset.company +
						'/best?limit=10';
					getReviewsAndDisplay(url, newActiveTabPanel);
					break;

				case 2: // worse reviews
					newActiveTabPanel = updateTabPanel(reviewTabPanelElement, 2);
					url =
						'/api/reviews/' +
						newActiveTabPanel.dataset.company +
						'/worse?limit=10';
					getReviewsAndDisplay(url, newActiveTabPanel);
					break;
			}
		});
	}
})();

// Schedule Tab Bar (Next/Prev) bus
(function ScheduleTabBar() {
	const scheduleTabBarElement = document.querySelector('#schedule-tab-bar');
	if (scheduleTabBarElement) {
		let scheduleTabPanelsElement = scheduleTabBarElement.nextSibling;
		let scheduleTabBar = new MDCTabBar(scheduleTabBarElement);
		scheduleTabBar.listen('MDCTabBar:activated', (evt) => {
			updateTabPanel(scheduleTabPanelsElement, evt.detail.index);
		});
		attachReviewEvents();
	}
})();

(function CompanyRoutesTabBar() {
	const companyRoutesTabBarElement = document.querySelector(
		'#mdc-tab__company-routes'
	);
	if (companyRoutesTabBarElement) {
		new MDCTabBar(companyRoutesTabBarElement);
		let tabs = companyRoutesTabBarElement.querySelectorAll('.mdc-tab');
		let today = new Date();
		[].map.call(tabs, (tab) => {
			tab.setAttribute(
				'href',
				`${tab.getAttribute('href')}/${today.toISOString().split('T')[0]}`
			);
		});
	}
})();

//datepicker
(function Datepicker_() {
	const options = {
		minDate: 'today',
		altInput: true,
		altFormat: 'D, d F',
		dateFormat: 'Y-m-d',
		disableMobile: true,
		onChange: () => {
			analyticsTracker.trackEvent(
				document.documentElement.dataset.page,
				'Datepicker',
				'SELECTED'
			);
		},
		onReady: () => {
			// const date_field_mobile = document.querySelector(
			// 	'.date-field[type=date]'
			// );
			// if (date_field_mobile) {
			// 	date_field_mobile.tabIndex = 0;
			// }
		},
	};
	const date_fields = document.querySelectorAll('.date-field');
	for (const date_field of date_fields) {
		if (date_field.dataset.i18n) {
			options.locale = JSON.parse(date_field.dataset.i18n);
		}
		flatpickr(date_field, options);
	}

	const flatpickrDialogTrigger = document.querySelector(
		'#flatpickr-dialog-trigger'
	);
	const flatpickrDialogElement = document.querySelector('#flatpickr-dialog');
	const flatpickrInline = document.querySelector('.flatpickr-inline');

	if (flatpickrDialogTrigger && flatpickrDialogElement && flatpickrInline) {
		const flatpickrDialog = new MDCDialog(flatpickrDialogElement);
		flatpickrDialogTrigger.addEventListener('click', () => {
			flatpickrDialog.open();
			flatpickrDialog.listen('MDCDialog:closed', () => {
				flatpickrDialog.getDefaultFoundation().adapter.releaseFocus();
			});
		});
		const inlineOptions = {
			minDate: 'today',
			inline: true,
			defaultDate: flatpickrInline.dataset.defaultDate,
			onChange: (selectedDates, dateString, instance) => {
				console.log(dateString);
				if (dateString && dateString.length > 0) {
					var url = window.location.href;
					var regex = /\/\d{4}-\d{2}-\d{2}/;
					var dateStringSlash = '/' + dateString;
					if (url.match(regex)) {
						url = url.replace(regex, dateStringSlash);
					} else {
						url += dateStringSlash;
					}
					window.location.href = url;
				}
			},
		};
		if (flatpickrInline.dataset.i18n) {
			inlineOptions.locale = JSON.parse(flatpickrInline.dataset.i18n);
		}
		flatpickr(flatpickrInline, inlineOptions);
	}
})();

var menuElements = document.querySelectorAll('[data-toggle-menu]');
[].map.call(menuElements, (el) => {
	el.addEventListener('click', (event) => {
		let targetElementSelector = event.currentTarget.dataset.target;
		var menu = new MDCMenu(document.querySelector(targetElementSelector));
		menu.open = !menu.open;
	});
});

// Company rating tab bar
(function RatingTabBar() {
	const ratingTabBarElement = document.querySelector('#rating-tab-bar');
	if (ratingTabBarElement) {
		new MDCTabBar(ratingTabBarElement);
		attachReviewEvents();
	}
})();

// Review tab bar
(function ReviewTabBar() {
	const reviewTabBarElement = document.querySelector('#review-tab-bar');
	if (reviewTabBarElement) {
		new MDCTabBar(reviewTabBarElement);
	}
})();

// Review tab bar
(function RouteTabBar() {
	const routeTabBarElement = document.querySelector('#routes-tab-bar');
	if (routeTabBarElement) {
		new MDCTabBar(routeTabBarElement);
	}
})();

// Search Form
function SearchForm(form) {
	if (form) {
		var originInputEl = form.querySelector('.origin-field');
		var destinationInputEl = form.querySelector('.destination-field');
		var dateInputEl = form.querySelector('input[name=date]');
		var swapTrigger = form.querySelector('.swap-routes-locations-trigger');

		function handleFormSubmit(evt) {
			var query =
				form.querySelector('.default-query').value +
				' ' +
				originInputEl.value +
				' ' +
				destinationInputEl.value;
			form.querySelector('.default-query').value = query;

			var hasTypedOrigin =
				originInputEl.value && originInputEl.value.length > 0;
			var hasTypedDestination =
				destinationInputEl.value && destinationInputEl.value.length > 0;
			var hasTypedText = hasTypedOrigin || hasTypedDestination;
			var hasChosenAll =
				originInputEl.dataset.id && destinationInputEl.dataset.id;
			if (hasChosenAll) {
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					'AutoComplete_FORM_SUBMITED',
					'SELECTED_ALL'
				);
				evt.preventDefault();
				var url = form
					.getAttribute('data-action')
					.replace('$origin', originInputEl.dataset.id)
					.replace('$destination', destinationInputEl.dataset.id);

				if (dateInputEl && dateInputEl.value && dateInputEl.value.length > 0) {
					url += '/' + dateInputEl.value;
				}
				window.location.href = url;
				return false;
			} else if (!hasChosenAll && hasTypedText) {
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					'AutoComplete_FORM_SUBMITED',
					'TYPED_TEXT'
				);
			} else if (!originInputEl.dataset.id) {
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					'AutoComplete_FORM_SUBMITED',
					'MISSED_ORIGIN'
				);
				evt.preventDefault();
				originInputEl.focus();
			} else if (!destinationInputEl.dataset.id) {
				analyticsTracker.trackEvent(
					document.documentElement.dataset.page,
					'AutoComplete_FORM_SUBMITED',
					'MISSED_DESTINATION'
				);
				evt.preventDefault();
				destinationInputEl.focus();
			}
		}
		if (swapTrigger) {
			swapTrigger.addEventListener('click', function (evt) {
				var origin = {
					id: originInputEl.getAttribute('data-id'),
					value: originInputEl.getAttribute('value'),
				};
				var destination = {
					id: destinationInputEl.getAttribute('data-id'),
					value: destinationInputEl.getAttribute('value'),
				};

				originInputEl.setAttribute('data-id', destination.id);
				originInputEl.setAttribute('value', destination.value);

				destinationInputEl.setAttribute('value', origin.value);
				destinationInputEl.setAttribute('data-id', origin.id);
			});
		}

		form.addEventListener('submit', handleFormSubmit);
	}
}
var forms = document.querySelectorAll('.search-form');
[].map.call(forms, (form) => {
	new SearchForm(form);
});

// Reviews
(function Reviews() {
	/** READ Review item */
	const reviewItemDialogTriggers = document.querySelectorAll(
		'.review-item__dialog-trigger'
	);
	const reviewItemDialogEl = document.querySelector('#review-item-dialog');
	if (reviewItemDialogTriggers && reviewItemDialogEl) {
		const reviewItemDialog = new MDCDialog(reviewItemDialogEl);

		let reviewItemDialogTitle = reviewItemDialogEl.querySelector(
			'#review-item__dialog-title'
		);
		let reviewItemDialogContent = reviewItemDialogEl.querySelector(
			'#review-item__dialog-content'
		);

		let reviewIcon = reviewItemDialogTitle.querySelector('.review-icon');
		let reviewCompany = reviewItemDialogTitle.querySelector('.review-company');
		let reviewComment =
			reviewItemDialogContent.querySelector('.review-comment');

		const settings = {};

		// Listen to review read buttons clicks
		[].map.call(reviewItemDialogTriggers, (el) => {
			el.addEventListener(
				'click',
				(event) => {
					let targetElement = event.target;
					if (
						!targetElement.classList.contains('review-item__dialog-trigger')
					) {
						targetElement = targetElement.closest(
							'.review-item__dialog-trigger'
						);
					}
					Object.assign(settings, targetElement.dataset);
					reviewItemDialog.open();
				},
				false
			);
		});

		reviewItemDialog.listen('MDCDialog:opening', (event) => {
			reviewIcon.innerHTML = settings.reviewIcon;
			reviewIcon.classList.add(settings.reviewIcon);
			reviewComment.innerHTML = settings.reviewComment;
			reviewCompany.innerHTML = settings.reviewCompany;
		});

		reviewItemDialog.listen('MDCDialog:closed', () => {
			reviewIcon.classList.remove(settings.reviewIcon);
			reviewIcon.innerHTML = '';
			reviewComment.innerHTML = '';
			reviewCompany.innerHTML = '';
		});
	}

	const reviewListDialogTriggers = document.querySelectorAll(
		'.review-list__dialog-trigger'
	);
	const reviewListDialogEl = document.querySelector('#review-list-dialog');

	if (!reviewListDialogTriggers || !reviewListDialogEl) {
		return;
	}

	const reviewListDialog = new MDCDialog(reviewListDialogEl);

	let dialogTitle = reviewListDialogEl.querySelector(
		'#review-list__dialog-title'
	);
	let dialogContent = reviewListDialogEl.querySelector(
		'#review-list__dialog-content'
	);

	const settings = {};

	// Listen to review read buttons clicks
	[].map.call(reviewListDialogTriggers, (el) => {
		el.addEventListener(
			'click',
			(event) => {
				let targetElement = event.target;
				if (!targetElement.classList.contains('review-list__dialog-trigger')) {
					targetElement = targetElement.closest('.review-list__dialog-trigger');
				}
				Object.assign(settings, targetElement.dataset);
				reviewListDialog.open();
			},
			false
		);
	});

	reviewListDialog.listen('MDCDialog:opened', (event) => {
		analyticsTracker.trackEvent(
			document.documentElement.dataset.page,
			'Review_READ_OPEN',
			settings.company
		);

		const url = `/api/reviews/${settings.company}/${settings.reviews}?limit=100`;
		fetch(url)
			.then((response) => response.json()) // retorna uma promise
			.then((result) => render(result))
			.then((renderedHTML) => {
				const badge = document.querySelector(
					`#reviews-badge-${settings.company}`
				);
				if (badge) {
					dialogTitle.innerHTML = badge.outerHTML;
				}
				dialogContent.classList.add('mdc-list--reviews');
				dialogContent.innerHTML = renderedHTML;
				reviewListDialog.layout();
				attachReviewEvents();
			})
			.catch((err) => console.error(err));
	});

	reviewListDialog.listen('MDCDialog:closed', () => {
		dialogTitle.innerHTML = '';
		dialogContent.innerHTML = '';
		analyticsTracker.trackEvent(
			document.documentElement.dataset.page,
			'Review_READ_CLOSE',
			settings.company
		);
		console.log('MDCDialog:closing');
	});
})();
