"use strict";

// LazyLoad images
function LazyLoad(){
  
  window.addEventListener("load", function() {
    var lazyImages = [].slice.call(document.querySelectorAll(".b-lazy"));
  
    if ("IntersectionObserver" in window) {

      var lazyImageObserver = new IntersectionObserver(function(entries, observer) {

        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var lazyImage = entry.target;
            if(lazyImage.classList.contains('b-lazy-bg')){
              lazyImage.style.backgroundImage = `url("${lazyImage.dataset.src}")`;
            } 
            else if(lazyImage.dataset.src){
              lazyImage.src = lazyImage.dataset.src;
            }
            else if(lazyImage.dataset.srcset) {
              lazyImage.srcset = lazyImage.dataset.srcset;
            }
            lazyImage.classList.remove("b-lazy");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });
  
      lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    } 
    else {
      // Possibly fall back to a more compatible method here
      var active = false;
      var lazyLoad = function() {

        if (active === false) {
          active = true;

          setTimeout(function() {

            lazyImages.forEach(function(lazyImage) {
              if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && 
                lazyImage.getBoundingClientRect().bottom >= 0) && 
                getComputedStyle(lazyImage).display !== "none") {

                lazyImage.src = lazyImage.dataset.src;
                if(lazyImage.dataset.srcset) {
                  lazyImage.srcset = lazyImage.dataset.srcset;
                }
                lazyImage.classList.remove("b-lazy");

                lazyImages = lazyImages.filter(function(image) {
                  return image !== lazyImage;
                });

                if (lazyImages.length === 0) {
                  document.removeEventListener("scroll", lazyLoad);
                  window.removeEventListener("resize", lazyLoad);
                  window.removeEventListener("orientationchange", lazyLoad);
                }
              }
            });
            active = false;
          }, 200);
        }
      };

      document.addEventListener("scroll", lazyLoad);
      window.addEventListener("resize", lazyLoad);
      window.addEventListener("orientationchange", lazyLoad);
    }
  });
}

const _default = LazyLoad;
export { _default as default };